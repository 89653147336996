<template>
  <div>
    <div class="flex-align box-st" :style="stepStyle">
      <div class="margin-right-12">
        <span class="font-primary">{{ startValue }}</span> / {{ endValue }}단계
      </div>
      <div class="step-shape">
        <div class="flex-align">
          <div class="circle"
               :class="{'active': idx<startValue}"
               v-for="(end, idx) in endValue" :key="`e${idx}`">
            <div class="linear"
                 :class="{'active': idx<startValue-1}"
                 v-if="idx<endValue-1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      startValue: {
        type: Number
      },
      endValue: {
        type: Number
      },
      backgroundColor: {
        type: String,
        default: 'white'
      }
    },
    name: "BoxStep",
    created() {
    },
    data() {
      return {}
    },
    computed: {
      stepStyle() {
        let style = {
          color: '#837c7a',
          border: '1px solid #d1cecb',
          borderRadius: '5px',
          padding: '12px 16px',
          fontSize: '14px',
          backgroundColor: this.backgroundColor
        }
        return style;
      }
    },
    methods: {}
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .step-shape
    position relative

    .circle
      background-color $border2
      border-radius 4px
      padding 4px
      margin 0 6px
      position relative

    .circle:first-child
      margin-left 0

    .circle:last-child
      margin-right 0

    .linear
      position absolute
      top 3px
      left 8px
      background-color $border2
      width 13px
      height 2px

    .active
      background-color $primary

</style>